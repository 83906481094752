<template>
	<div
		v-if="content"
		:ref="'button'"
		:class="['c-contact-button group h-48 >=768:h-64', classes]"
	>
		<button
			v-tracking="`Contact, Click, ${getPath}`"
			class="
				inline-block
				h-full
				bg-action
				text-white
				rounded-full
				flex
				items-center
				text-left
				mx-auto
			"
			@click="onShow()"
		>
			<UmbracoImageExt
				:alt="content[0].image.altText"
				:source-url="content[0].image.cropUrl || content[0].image.url"
				:source-width="content[0].image.width"
				:source-height="content[0].image.height"
				:widths="[56]"
				:aspect-ratio="1 / 1"
				class="
					hidden
					h-56
					w-56
					m-5
					mr-12
					rounded-full
					overflow-hidden
					>=768:block
				"
			/>
			<span
				v-if="multipleContacts > 1"
				class="hidden uppercase font-semibold >=768:block"
				v-html="`Kontakt os`"
			></span>
			<span
				v-else
				class="
					leading-initial
					hidden
					uppercase
					font-semibold
					>=768:block
				"
				v-html="`Kontakt <br/>${getFirstname(content[0].name)}`"
			></span>
			<span
				class="text-14 ml-25 block uppercase font-semibold >=768:hidden"
				v-html="`Kontakt os`"
			></span>
			<span
				class="
					relative
					rounded-full
					w-32
					h-32
					mx-16
					flex
					items-center
					group-hover:bg-white
					transform
					transition
					duration-250
					ease-in-out
				"
			>
				<SvgLinkCaret
					class="
						w-8
						h-12
						absolute
						left-13
						right-11
						text-white
						group-hover:text-action
						transform
						transition
						duration-250
						ease-in-out
					"
				/>
			</span>
		</button>
		<Portal v-if="isOpen" to="SpaOverlay">
			<div
				class="
					fixed
					top-0
					left-0
					w-full
					h-full
					bg-black bg-opacity-30
					z-100
				"
				@click="onClose(index)"
			>
				<div
					class="
						fixed
						top-0
						right-0
						w-full
						h-full
						bg-action
						text-white
						z-20
						py-95
						px-20
						>=768:px-100 >=768:w-half
						overflow-auto
					"
					@click.stop
				>
					<button
						class="
							fixed
							rounded-full
							bg-white
							z-20
							w-48
							h-48
							flex
							justify-center
							items-center
							transform
							hover:rotate-180
							transition
							duration-500
							ease-in-out
							top-30
							right-20
							>=768:top-33 >=768:right-33
						"
						@click="onClose()"
					>
						<SvgClose class="w-12 h-12 text-blue" />
					</button>
					<div class="mt-20">
						<div
							v-for="(person, index) in content"
							:key="index"
							:class="index != 0 ? 'mt-95' : 'mt-0'"
						>
							<div class="flex items-center mb-10">
								<UmbracoImageExt
									v-if="person.image"
									:alt="person.image.altText"
									:source-url="
										person.image.cropUrl || person.image.url
									"
									:source-width="person.image.width"
									:source-height="person.image.height"
									:widths="[56, 100]"
									:lazyload="false"
									sizes="(max-width: 768px) 100px, 56px"
									:aspect-ratio="1 / 1"
									class="
										block
										w-100
										>=768:w-56
										m-5
										mr-25
										rounded-full
										overflow-hidden
									"
								/>
								<div
									class="
										leading-initial
										text-20
										flex flex-col
										font-bold
										>=768:text-24
										uppercase
										leading-basic
									"
								>
									<span>Kontakt</span>
									<span v-html="person.name"></span>
								</div>
							</div>
							<div
								v-if="
									person.text || person.phone || person.email
								"
								class="text-14 >=768:text-16"
							>
								<p
									v-if="person.text"
									class="
										border-b border-white border-opacity-30
										mb-35
										pb-35
									"
									v-html="person.text"
								></p>
								<a
									v-if="person.phone"
									class="flex items-center mt-25"
									:href="`tel:${person.phone}`"
								>
									<SvgPhone
										class="w-25 h-25 text-white mr-15"
										name="phone"
									/>
									<span
										class="underline"
										v-html="person.phone"
									></span>
								</a>
								<a
									v-if="person.email"
									class="flex items-center mt-25"
									:href="`mailto:${person.email}`"
								>
									<SvgEmail
										class="w-25 h-25 text-white mr-15"
										name="email"
									/>
									<span
										class="underline"
										v-html="person.email"
									></span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Portal>
	</div>
</template>

<script>
import UmbracoImageExt from '~/components/main/UmbracoImageExt';
import SvgLinkCaret from '~/assets/svgs/link-caret.svg?inline';
import SvgClose from '~/assets/svgs/close.svg?inline';
import SvgPhone from '~/assets/svgs/phone.svg?inline';
import SvgEmail from '~/assets/svgs/email.svg?inline';

export default {
	name: 'ContactButton',

	components: {
		UmbracoImageExt,
		SvgLinkCaret,
		SvgClose,
		SvgPhone,
		SvgEmail,
	},

	props: {
		content: {
			type: Array,
			required: false,
		},
		classes: {
			type: String,
			required: false,
			default:
				'z-100 w-175 fixed <768:bottom-20 <768:left-half >=768:w-210 >=768:-mt-33  >=768:fixed >=768:bottom-25 >=768:right-30',
		},
	},

	data() {
		return {
			isOpen: false,
			template: null,
		};
	},

	computed: {
		multipleContacts() {
			return this.content.length;
		},
		getPath() {
			return this.$store.state.route.fullPath;
		},
	},

	created() {
		this.$on('overlay.close', this.onClose);
		this.template = this.$store.state.template;
	},

	mounted() {
		const button = this.$refs.button;
		let initScrollTop = 0;
		document.addEventListener('scroll', (e) => {
			const scrollTop = window.pageYOffset || document.body.scrollTop;
			if (scrollTop > initScrollTop) {
				button.classList.add('scrolling');
			} else {
				button.classList.remove('scrolling');
			}
			initScrollTop = scrollTop <= 0 ? 0 : scrollTop;
		});
	},

	methods: {
		getFirstname(fullname) {
			return fullname.split(' ')[0];
		},
		onClose() {
			this.isOpen = false;
		},
		onShow(index) {
			this.index = index;
			this.isOpen = true;
		},
	},
};
</script>
<style lang="postcss">
/*! purgecss start ignore */
@screen <768 {
	.c-contact-button {
		@apply transition-all !important;
		@apply duration-300 !important;
		@apply opacity-100 !important;
		transform: translateX(-50%) !important;
	}
	.c-contact-button.scrolling {
		@apply opacity-0 !important;
	}
}
/*! purgecss end ignore */
</style>
